<template>
  <section class="tables painel_controle">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3 class="card-title mb-0">
                Chamadas <span class="text-success">Efetuadas </span>
                <span class="text-muted ml-2">{{ items.length }}</span>
              </h3>

              <b-button
                :to="{
                  name: 'PainelChamadasRecebidasAdmin',
                }"
                variant="primary"
              >
                <span class="text-muteds">Ir para chamadas recebidas</span>
              </b-button>
            </div>
            <h5 v-if="user" class="mb-0">
              <span class="text-primary">{{ user.nome }} </span>
              <br />
              <span class="text-primary"> Data: {{ callDay }}</span>
            </h5>

            <div class="set_calendar">
              <div class="set_calendar_container">
                <div>
                  <b-button variant="primary" v-b-modal.modal-xl
                    >Pesquisa Avançada</b-button
                  >
                </div>

                <div>
                  <label for="dateEnd" class="text-muted mb-0"
                    >Status da chamada</label
                  >

                  <!-- <v-date-picker v-model="dateEnd"  mode="single" > -->
                  <b-form-select
                    v-model="selected"
                    size="sm"
                    :options="options"
                  ></b-form-select>
                </div>

                <div>
                  <label for="dateinit" class="text-muted mb-0"
                    >Data início</label
                  >

                  <v-date-picker
                    v-model="dateStart"
                    mode="single"
                    :max-date="new Date()"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="
                          px-2
                          py-1
                          border
                          rounded
                          focus:outline-none focus:border-blue-300
                        "
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>

                <div>
                  <label for="dateEnd" class="text-muted mb-0"
                    >Data Final</label
                  >

                  <!-- <v-date-picker v-model="dateEnd"  mode="single" > -->
                  <v-date-picker
                    v-model="dateEnd"
                    mode="single"
                    :max-date="new Date()"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="
                          px-2
                          py-1
                          border
                          rounded
                          focus:outline-none focus:border-blue-300
                        "
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>

                <div class="d-flex flex-column justify-content-end">
                  <label for="" class="text-muted mb-0"> </label>

                  <button
                    class="btn btn-primary"
                    @click="searchCalls"
                    :style="
                      loadingTable ? 'opacity: 0.3;     cursor: progress;' : ''
                    "
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>

              <!-- :sort-by.sync="sortBy" -->
            <b-table
              ref="selectableTable"
              :items="items"
              id="table-list"
              class="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-desc.sync="sortDesc"
              :busy="loadingTable"
              :key="count"
            >
              <template #table-busy>
                <div class="text-center text-white my-2 loadding-white-mode">
                  <h5 class="" v-if="allCallsLength > 0">
                    Encontrado
                    <span class="text-primary"> {{ allCallsLength }} </span>
                    <span v-if="allCallsLength == 1">chamada</span>
                    <span v-else>chamadas</span>
                  </h5>

                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Carregando...</strong>
                </div>
              </template>

              <template v-slot:cell(data)="data">
                <span>{{ data.value | formatDateTime }} </span>
              </template>

              <template v-slot:cell(duração)="data">
                <span>{{ data.value | formatForMinutes }} </span>
              </template>

              <template v-slot:cell(para)="data">
                <span>{{ data.value }} </span>
              </template>

              <template v-slot:cell(audio)="data">
                <!-- <span>{{ data.value }} </span> -->

                <audio controls preload="none" v-if="data.item.audio">
                  <source :src="data.value" type="audio/mpeg" />
                </audio>
              </template>

              <template v-slot:cell(status)="data">
                <span
                  :class="
                    data.value == 'failed'
                      ? 'text-danger'
                      : data.value == 'no-answer'
                      ? 'text-warning'
                      : data.value == 'busy'
                      ? 'text-info'
                      : 'text-success'
                  "
                  >{{ data.value }}
                </span>
              </template>
              <!-- <template v-slot:cell(ação)="data">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'Home', params: { id: data.item.userId } }"
                  ><i class="mdi mdi-eye-outline text-primary mr-2"></i
                  >Detalhes</b-button
                >
              </template> -->
            </b-table>

            <!-- <div
              v-else
              class="
                text-center text-white
                my-2 my-loadding
                loadding-white-mode
              "
            >
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> Aguarde ...</strong>
            </div> -->

            <div v-if="items.length === 0 && !loadingTable">
              <h5 class="text-muted text-center">
                Nenhum resultado encontrado
              </h5>
            </div>

            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-xl"
      size="xl"
      :title="user ? user.nome : ''"
      scrollable
      no-close-on-backdrop
      hide-footer
      :content-class="
        !this.$store.state.showWhiteMode
          ? 'modallEditarStatus'
          : 'modallEditarStatusWhite'
      "
    >
      <ModalFiltrosChamadas :user="user" />
    </b-modal>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

import TwilioService from "@/services/twilio.service";
import UserService from "@/services/user.service";
import ModalFiltrosChamadas from "./components/ModalFiltrosChamadas.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  props: ["id"],
  components: { ModalFiltrosChamadas },

  data: function () {
    return {
      perPage: 10,
      currentPage: 1,
      sortDesc: true,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      loadingTable: false,
      fields: [
        // { key: "de", sortable: false },
        { key: "para", sortable: false },
        { key: "direção", sortable: false },
        { key: "duração", sortable: false },
        { key: "data", sortable: false },

        { key: "status", sortable: false },
        { key: "audio", sortable: false },
        // { key: "ação", sortable: false },
      ],
      items: [],
      count: 0,
      allCallsLength: 0,

      dateStart: new Date(),
      dateEnd: new Date(),
      user: undefined,
      callDay: "",
      today: undefined,
      // selected: null,
      selected: "",

      options: [
        { value: "", text: "Todas" },
        { value: "completed", text: "Completas" },
        { value: "no-answer", text: "Não atende" },
        { value: "failed", text: "Falha" },
        { value: "busy", text: "Ocupado" },
      ],
    };
  },

  methods: {
    async getUser() {
      this.loadingTable = true;

      try {
        const res = await UserService.get(this.id);
        this.user = res.data;

        // console.log(this.user);

        this.getHistoryCalls(this.user);
      } catch (error) {
        console.error(error);
        this.loadingTable = false;
      }
    },

    async getHistoryCalls(user) {
      this.loadingTable = true;
      this.allCallsLength = 0;


      const dateStart = this.formateDate(this.dateStart);
      const dateEnd = this.tomorrowDate(this.dateEnd);
      const status = !this.selected ? "" : `Status=${this.selected}`;
      const params = {
        user: user.email,
        dateStart: dateStart,
        dateEnd: dateEnd,
        status,
      };
      this.loadingTable = true;
      this.items = [];
      this.count++;

      // this.formateDate( this.dateStart      )

      try {
        const res = await TwilioService.getCallDetalls(params);
        // console.log(res);

        const allCalls = res.data.calls;
        this.allCallsLength = allCalls.length;
        let arrayCount = 0;

        if (allCalls.length === 0) {
          this.count++;
          this.loadingTable = false;
          return;
        }

        allCalls.forEach((item) => {
          TwilioService.getEventsFromEachCall(item.sid)

            .then((res) => {
              let to = res.data.events[0].request.parameters.to;
              // console.log(to);

              TwilioService.getRecording(item.sid)
                .then((res) => {
                  this.loadingTable = true;

                  // console.log(res);
                  // const recording = res.data.recordings[0].media_url;
                  const recording =
                    res.data.recordings.length > 0
                      ? res.data.recordings[0].media_url
                      : "";
                  // console.log(recording);

                  // console.log(item);
                  this.items.push({
                    // de: item.from,
                    // de: user.nome,
                    direção: item.direction,
                    duração: item.duration,
                    status: item.status,
                    data: item.date_created,
                    para: to,
                    audio: recording,
                  });

                  arrayCount++;

                  if (arrayCount === allCalls.length) {
                    this.count++;
                    this.loadingTable = false;
                  }
                })
                .catch((e) => {
                  arrayCount++;
                  console.error(e);
                });
            })
            .catch((e) => {
              arrayCount++;
              console.error(e);
              this.loadingTable = false;
            });
        });

        this.setDateInSCreen();
      } catch (error) {
        console.error(error);
      }
    },

    formateDate(date) {
      if (date) {
        const dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];

        return dateString;
      }
    },
    tomorrowDate() {
      //pega a data atual e seleciona o dia seguinte (amanha) - twilio
      const d = new Date(this.dateEnd);
      d.setDate(d.getDate() + 1);
      const newDateEnd = d.toLocaleDateString().split("/").reverse().join("-");

      return newDateEnd;
    },

    searchCalls() {
      this.getHistoryCalls(this.user);
    },

    setDateInSCreen() {
      this.callDay =
        this.formateDate(this.dateStart) === this.today
          ? "Hoje"
          : `${this.formateDate(this.dateStart)
              .split("-")
              .reverse()
              .join("/")}  -> ${this.formateDate(this.dateEnd)
              .split("-")
              .reverse()
              .join("/")}`;
    },
  },

  watch: {
    currentPage() {
      this.count++;
    },

   

    sortDesc() {
      this.count++;
    },

    selected() {
      this.getUser();
    },
  },

  mounted() {
    this.today = this.formateDate(new Date());
    this.getUser();

    // console.log(this.isAdmin);
  },

  computed: {
    rows() {
      return this.items.length;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.set_calendar {
  width: 100%;

  display: flex;

  justify-content: flex-end;

  z-index: 4;

  .set_calendar_container {
    /* max-width: 350px; */
    display: flex;
    position: relative;
    align-items: flex-end;

    > div {
      margin: 0 10px;
      display: flex;
      flex-direction: column;
    }
  }

  .calendarStart {
    position: absolute;
    top: 50px;
    left: -36px;
  }

  .search__by__number {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 800px) {
  .table-list {
    height: 75vh;
  }
}

.custom-select {
  min-height: 34px;
}
</style>

<style lang="scss">
.painel_controle {
  table {
    text-align: center;
  }

  td[aria-colindex="1"],
  th[aria-colindex="1"] {
    text-align: left;
  }
}
</style>
