<template>
  <div>
    <div class="d-flex justify-content-between">
      <h5 class="card-title m-0">
        Chamadas
        <span class="text-muted ml-2"> {{ items.length }}</span>
      </h5>

      <!-- <span class="card-title text-warning">* O resultado da busca trará chamadas de qualquer data</span> -->
    </div>

    <div class="form-group">
      <form
        class="d-flex justify-content-end align-items-center"
        @submit.prevent="search"
      >
        <!-- &nbsp; -->

        <div class="d-flex">
          <TheMask
            v-model="phone_number"
            :mask="['+#################']"
            class="form-control w-auto mr-2"
            placeholder="Número do telefone"
            id="phone-number"
          />
          <b-button variant="primary" type="submit">Buscar</b-button>
        </div>
      </form>

      <div class="text-right mt-1">
        <span class="text-muted"
        style="font-size: 12px;"
          >* O resultado da busca retorna todas as chamadas efetuadas  para um número, para  <strong>qualquer data</strong>  vinculado ao mesmo. </span
        >
      </div>
    </div>

    <b-table
      ref="selectableTable"
      :items="items"
      id="table-list"
      class="table-list"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingTable"
      :key="count"
    >
      <template #table-busy>
        <div class="text-center text-white my-2 loadding-white-mode">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Carregando...</strong>
        </div>
      </template>

      <template v-slot:cell(data)="data">
        <span>{{ data.value | formatDateTime }} </span>
      </template>

      <template v-slot:cell(duração)="data">
        <span>{{ data.value | formatForMinutes }} </span>
      </template>

      <template v-slot:cell(para)="data">
        <span>{{ data.value }} </span>
      </template>

      <template v-slot:cell(audio)="data">
        <!-- <span>{{ data.value }} </span> -->

        <audio controls preload="none" v-if="data.item.audio">
          <source :src="data.value" type="audio/mpeg" />
        </audio>
      </template>

      <template v-slot:cell(status)="data">
        <span
          :class="
            data.value == 'failed'
              ? 'text-danger'
              : data.value == 'no-answer'
              ? 'text-warning'
              : data.value == 'busy'
              ? 'text-info'
              : 'text-success'
          "
          >{{ data.value }}
        </span>
      </template>
      <!-- <template v-slot:cell(ação)="data">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'Home', params: { id: data.item.userId } }"
                  ><i class="mdi mdi-eye-outline text-primary mr-2"></i
                  >Detalhes</b-button
                >
              </template> -->
    </b-table>
    <div class="text-center">
      <span class="text-danger">{{ msgNotFound }}</span>
    </div>

    <!-- <span>Nao foram econtrados result</span> -->

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import axios from "axios";
import { TheMask } from "vue-the-mask";
import TwilioService from "../../../../services/twilio.service";

export default {
  components: { TheMask },
  props: ["user"],
  data() {
    return {
      items: [], // lista de chamadas

      sortBy: "data",
      perPage: 5,
      currentPage: 1,
      // sortDesc: true,
      sortDesc: true,
      sortable: true,
      loadingTable: false,
      count: 0,
      fields: [
        // { key: "de", sortable: false },
        { key: "para", sortable: false },
        { key: "direção", sortable: false },
        { key: "duração", sortable: false },
        { key: "data", sortable: false },

        { key: "status", sortable: false },
        { key: "audio", sortable: false },
        // { key: "ação", sortable: false },
      ],
      phone_number: "",
      msgNotFound: "",
    };
  },

  mounted() {
   
  },

  methods: {
    async search() {
      if (!this.phone_number) return;
      this.loadingTable = true;
      this.msgNotFound = "";
      this.items = [];

      try {
        const res = await axios.post(
          "https://call-filters-8261-dev.twil.io/index",
          {
            phone_number: this.phone_number,
            email: this.user.email,
          }
        );
        const allCalls = res.data;
        if (allCalls.length == 0) {
          this.msgNotFound = "Nenhum resultado: " + this.phone_number;
          this.loadingTable = false;
          return;
        }
        this.handleEventsFromEachCall(allCalls);
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
      }
    },

    handleEventsFromEachCall(allCalls) {
      // console.log(allCalls);
      for (let itemCall of allCalls) {
        const item = itemCall.call;
        const to = itemCall.to;

        TwilioService.getRecording(item.sid)
          .then((res) => {
            this.loadingTable = true;

            // console.log(res);
            // const recording = res.data.recordings[0].media_url;
            const recording =
              res.data.recordings.length > 0
                ? res.data.recordings[0].media_url
                : "";
            // console.log(recording);

            // console.log(item.dateCreated);

            this.items.push({
              // de: item.from,
              // de: user.nome,
              direção: item.direction,
              duração: item.duration,
              status: item.status,
              data: item.dateCreated,
              para: to,
              audio: recording,
            });

            this.count++;
            // if(count == allCalls.length){
            // }

            this.loadingTable = false;

            // console.log(index);
            // this.loadingTable = false;

            // this.forceUpdateTable();
          })
          .catch((e) => {
            console.error(e);
            this.loadingTable = false;
          });
      }
    },

    
  },

  computed: {
    rows() {
      return this.items.length;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    currentPage() {
      this.count++;
    },
  },
};
</script>

<style>
</style>